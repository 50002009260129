<template>
  <a-select
    :allowClear="allowClearSelection"
    :value="displayValue"
    class="selector"
    :style="styleOverride"
    :filterOption="filterOption"
    :placeholder="placeholder"
    :disabled="disabled"
    :size="size"
    :mode="mode"
    :defaultOpen="defaultOpen"
    :autoFocus="autoFocus"
    showSearch
    @change="handleSelectChange"
    @select="handleSelect"
    @search="handleSearch"
    @dropdownVisibleChange="handleDropdown"
    @blur="handleCancel"
  >
    <div slot="dropdownRender" slot-scope="menu">
      <VNodes :vnodes="menu" />
      <template v-if="!loading && hasAction">
        <a-divider style="margin: 4px 0" />
        <slot name="action" />
      </template>
    </div>
    <div v-if="loading" slot="notFoundContent" class="spinner-container">
      <a-spin size="small" />
    </div>
    <template v-else>
      <a-select-option
        v-for="option in options"
        :key="extractValue(option)"
        :value="extractValue(option)"
        :disabled="!!disableOption(option)"
        :title="extractLabel(option)"
      >
        <a-row style="width: 100%">
          <a-col :span="23">
            <span>
              {{ extractLabel(option) }}
            </span>
          </a-col>
          <a
            v-if="showLinkIcon"
            :href="extractLinkPath(option)"
            target="_blank"
          >
            <a-icon type="export" />
          </a>
          <InfoTooltip v-if="disableMessage && disableMessage(option) !== null">
            <template slot="info">
              {{ disableMessage(option) }}
            </template>
          </InfoTooltip>
        </a-row>
      </a-select-option>
    </template>
  </a-select>
</template>

<script>
import { mapActions } from "vuex";
import { debounce, uniqBy, get, isEmpty } from "lodash";
import InfoTooltip from "@/components/atoms/InfoTooltip.vue";

//IMPORTANT: Please make sure to register your module if it has not yet been
// registered.
export default {
  components: {
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
    InfoTooltip,
  },

  props: {
    // vuex module namespace from where we will map the actions
    namespace: {
      required: true,
      type: String,
    },
    // the state object name used to populate the suggestion list
    stateList: {
      required: true,
      type: [String, Function],
    },
    /* the state object name used to contain a list of all possible options
      (defaults to stateList if not provided) */
    aggregatedStateList: {
      required: false,
      type: String,
      default: null,
    },
    // the action that is used to fetch the list of options
    fetchMethod: {
      required: true,
      type: String,
    },
    value: {
      type: [String, Number, Object],
      default: null,
    },
    size: {
      type: String,
      default: undefined,
    },
    valueKey: {
      type: String,
      default: "value",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    labelKey: {
      type: String,
      default: "name",
    },
    styleOverride: {
      type: Object,
      default: function () {
        return {};
      },
    },
    defaultOption: {
      required: true,
    },
    placeholder: {
      type: String,
      default: "Please select a value",
    },
    loadingState: {
      type: String,
      default: undefined,
    },
    allowClearSelection: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "default",
    },
    defaultOpen: {
      type: Boolean,
      default: false,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    disableOption: {
      type: Function,
      default: () => {
        return false;
      },
    },
    disableMessage: {
      type: Function,
      default: undefined,
      required: false,
    },
    filterStateList: {
      type: Function,
      default: () => {
        return true;
      },
    },
    defaultPayloadParams: {
      type: Object,
      default: () => {},
    },
    filterOption: {
      default: false,
    },
    showLinkIcon: {
      type: Boolean,
      default: false,
    },
    linkKey: {
      type: String,
      default: "href",
    },
  },

  data() {
    return {
      currentValue: this.value,
      isSearching: false,
    };
  },

  computed: {
    state() {
      return this.$store.state[this.namespace];
    },
    dataList() {
      if (typeof this.stateList == "string") {
        return get(this.state, this.stateList);
      }

      return this.stateList(this.state);
    },
    aggregatedOptions() {
      if (this.aggregatedStateList) {
        return get(this.state, this.aggregatedStateList);
      }

      return this.options;
    },
    loading() {
      return this.state[this.loadingState];
    },
    options() {
      const options = [...this.dataList];

      if (!this.isSearching) {
        options.splice(0, 0, this.defaultOption);
      }

      const uniqOptions = uniqBy(options, this.valueKey);
      const notNullOptions = uniqOptions.filter((option) => option != null);
      const filtered = notNullOptions.filter(this.filterStateList);

      return filtered;
    },
    displayValue() {
      let match = this.aggregatedOptions.find(
        (option) => option[this.valueKey] == this.currentValue,
      );

      if (!match && !this.allowClearSelection && this.defaultOption) {
        match = this.defaultOption;
      }

      return get(match, this.labelKey, undefined);
    },
    hasAction() {
      return !!this.$slots.action;
    },
  },

  watch: {
    value: function (val) {
      this.currentValue = val;
    },
  },

  created() {
    window.addEventListener("keyup", (e) => {
      if (e.key == "Escape") {
        this.handleDropdown(false);
      }
    });
  },

  mounted() {
    if (this.defaultOpen) {
      this.$nextTick(() => {
        this.fetchData();
      });
    }
  },

  methods: {
    ...mapActions({
      fetchData(dispatch, payload = {}) {
        return dispatch(`${this.namespace}/${this.fetchMethod}`, {
          ...this.defaultPayloadParams,
          ...payload,
        });
      },
    }),
    handleSelect(value) {
      this.isSearching = false;
      this.currentValue = value;
      const selection = this.dataList.find((s) => s[this.valueKey] === value);

      this.$emit("select", selection);
    },
    handleSelectChange(newValue = null) {
      this.currentValue = newValue;
      this.$emit("change", this.currentValue);

      if (newValue === null) {
        this.$emit("close");
      }
    },
    handleDropdown(visible) {
      if (visible) {
        this.fetchData();
      } else {
        this.$emit("close");
      }
    },
    extractValue(option) {
      return option[this.valueKey];
    },
    extractLabel(option) {
      return option[this.labelKey];
    },
    extractLinkPath(option) {
      return option[this.linkKey];
    },
    handleSearch(term) {
      this.isSearching = !isEmpty(term);
      this.handleFetchData(term);
      this.$emit("onSearchTermChange", term);
    },
    handleCancel() {
      this.isSearching = false;
    },
    handleFetchData: debounce(
      function (term) {
        this.fetchData({ searchTerm: term });
      },
      150,
      { maxWait: 1000 },
    ),
  },
};
</script>

<style scoped>
.spinner-container {
  text-align: center;
}
.selector {
  width: 300px;
}
</style>
