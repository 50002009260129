<template>
  <a-tooltip>
    <template v-if="showTooltip" slot="title">
      {{ name }}
    </template>
    <a-tag
      :class="['icon-background', { small }]"
      :color="backgroundColor"
      :style="{ borderColor: borderColor }"
    >
      <component :is="iconDefinition" :size="iconSize" :color="textColor" />
      <div v-if="showName" :style="{ color: textColor }">{{ name }}</div>
    </a-tag>
  </a-tooltip>
</template>

<script>
import * as AntColors from "@ant-design/colors";
import * as iconDefinitions from "phosphor-vue";
import { ICON_SWATCH_COLORS, ICON_DEFAULT_COLOR } from "@/shared/colors.js";
import tinycolor from "tinycolor2";

const COLOR_PALETTE = {
  default: {
    background: 2,
    border: 3,
    text: 7,
  },
  yellow: {
    background: 2,
    border: 5,
    text: 8,
  },
  lime: {
    background: 2,
    border: 3,
    text: 7,
  },
  geekblue: {
    background: 2,
    border: 6,
    text: 7,
  },
  gray: {
    background: 3,
    border: 5,
    text: 7,
  },
};

const BORDER_TINT = 30;
const STROKE_TINT = 50;

export default {
  components: {},

  props: {
    name: {
      type: String,
      required: false,
      default: () => undefined,
    },
    icon: {
      type: String,
      required: false,
      default: () => "PhImage",
    },
    color: {
      type: String,
      required: false,
      default: () => ICON_DEFAULT_COLOR,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    showTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
    showName: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconSizeOverride: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    iconSize() {
      if (this.iconSizeOverride) {
        return this.iconSizeOverride;
      }

      return this.small ? "22" : "24";
    },
    iconDefinition() {
      return iconDefinitions[this.icon] ?? iconDefinitions.PhCube;
    },
    computedColor() {
      // The default value associated with the prop will not be used if `null` was passed.
      // This covers that case.
      return this.color ?? ICON_DEFAULT_COLOR;
    },
    antColor() {
      return ICON_SWATCH_COLORS[this.color];
    },
    antColorSwatch() {
      return AntColors[this.antColor] ?? AntColors.blue;
    },
    palette() {
      return COLOR_PALETTE[this.antColor] ?? COLOR_PALETTE.default;
    },
    backgroundColor() {
      if (this.antColor) {
        return this.antColorSwatch[this.palette.background];
      } else {
        return this.color;
      }
    },
    borderColor() {
      if (this.antColor) {
        return this.antColorSwatch[this.palette.border];
      } else {
        const tinyColor = tinycolor(this.color);

        return tinyColor.isLight()
          ? tinyColor.darken(BORDER_TINT).toHexString()
          : tinyColor.lighten(BORDER_TINT).toHexString();
      }
    },
    textColor() {
      if (this.antColor) {
        return this.antColorSwatch[this.palette.text];
      } else {
        const tinyColor = tinycolor(this.color);

        return tinyColor.isLight()
          ? tinyColor.darken(STROKE_TINT).toHexString()
          : tinyColor.lighten(STROKE_TINT).toHexString();
      }
    },
  },
};
</script>

<style scoped>
.icon-background {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.small {
  height: 32px;
}
</style>
