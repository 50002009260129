import { gql } from "@apollo/client";

const BASIC_TEAM_FRAGMENT = gql`
  fragment BasicTeamFragment on Team {
    id
    name
    href
    alias

    permissions {
      canUpdate
      permittedAttributesForUpdate
      canDelete
    }
  }
`;
const MANAGER_FRAGMENT = gql`
  fragment ManagerFragment on User {
    id
    name
    email
    href
    deactivatedAt
  }
`;

const MEMBERSHIP_FRAGMENT = gql`
  fragment MembershipFragment on TeamMembership {
    user {
      id
      name
      email
      href
      deactivatedAt
    }
    role
  }
`;
const FULL_TEAM_FRAGMENT = gql`
  fragment FullTeamFragment on Team {
    ...BasicTeamFragment
    responsibilities
    serviceStats {
      totalPassingChecks
      totalChecks
    }
    services {
      name
      href
    }
    contacts {
      type
      address
      targetHref
      displayName
    }

    permissions {
      canUpdate
      canDelete
    }

    memberships {
      nodes {
        user {
          name
          deactivatedAt
          href
          managedTeams {
            nodes {
              id
            }
          }
        }
      }
    }

    manager {
      ...ManagerFragment
    }
  }
  ${BASIC_TEAM_FRAGMENT}
  ${MANAGER_FRAGMENT}
`;

export const CONTACT_FRAGMENT = gql`
  fragment ContactFragment on Contact {
    id
    address
    displayName
    displayType
    type
    sharedAddress
    isDefault
    targetHref
  }
`;
export const TAG_FRAGMENT = gql`
  fragment TagFragment on Tag {
    id
    key
    value
  }
`;

export const FETCH_TEAM_QUERY_FULL = gql`
  query fetchTeam($id: ID!) {
    account {
      team(id: $id) {
        ...BasicTeamFragment
        responsibilities
        group {
          id
          alias
          name
          htmlUrl
        }
        hasChecks
        serviceStats {
          totalPassingChecks
          totalChecks
        }
        contacts {
          ...ContactFragment
          targetHref
        }
        manager {
          ...ManagerFragment
        }
        tags {
          nodes {
            ...TagFragment
          }
        }
        ancestors {
          nodes {
            id
            name
            href
          }
        }
        parentTeam {
          id
          name
          href
        }
        childTeams {
          nodes {
            id
            name
            href
          }
        }
      }
    }
  }
  ${BASIC_TEAM_FRAGMENT}
  ${CONTACT_FRAGMENT}
  ${MANAGER_FRAGMENT}
  ${TAG_FRAGMENT}
`;

export const FETCH_TEAM_QUERY_FULL_MEMBERS = gql`
  query fetchTeamFullMembers($id: ID!) {
    account {
      team(id: $id) {
        ...BasicTeamFragment
        responsibilities
        group {
          id
          alias
          name
          htmlUrl
        }
        hasChecks
        serviceStats {
          totalPassingChecks
          totalChecks
        }
        contacts {
          ...ContactFragment
        }
        memberships(first: 500) {
          nodes {
            ...MembershipFragment
          }
        }
        tags {
          nodes {
            ...TagFragment
          }
        }
        parentTeam {
          id
        }
        descendantTeams {
          nodes {
            id
          }
        }
      }
    }
  }
  ${BASIC_TEAM_FRAGMENT}
  ${CONTACT_FRAGMENT}
  ${MEMBERSHIP_FRAGMENT}
  ${TAG_FRAGMENT}
`;

export const TEAMS_QUERY_FULL = gql`
  query teams(
    $after: String
    $first: Int
    $sortBy: TeamSortEnum
    $search: String
  ) {
    account {
      teams(
        after: $after
        first: $first
        sortBy: $sortBy
        searchTerm: $search
      ) {
        totalCount
        pageInfo {
          endCursor
        }
        nodes {
          ...FullTeamFragment
        }
      }
    }
  }
  ${FULL_TEAM_FRAGMENT}
`;

export const TEAMS_QUERY_SIMPLE = gql`
  query teams(
    $sortBy: TeamSortEnum
    $searchTerm: String
    $withManager: Boolean = false
  ) {
    account {
      teams(sortBy: $sortBy, searchTerm: $searchTerm) {
        totalCount
        nodes {
          ...BasicTeamFragment
          manager @include(if: $withManager) {
            ...ManagerFragment
          }
          permissions {
            canUpdate
          }
        }
      }
    }
  }
  ${BASIC_TEAM_FRAGMENT}
  ${MANAGER_FRAGMENT}
`;

export const TEAMS_FOR_USER = gql`
  query teams($userId: ID!) {
    account {
      user(id: $userId) {
        teams {
          totalCount
          nodes {
            ...FullTeamFragment
          }
        }
      }
    }
  }
  ${FULL_TEAM_FRAGMENT}
`;

export const TEAM_CAMPAIGN_PERFORMANCE = gql`
  query teamCampaignPerformance($id: ID!, $statuses: [CampaignStatusEnum!]!) {
    account {
      team(id: $id) {
        teamCampaignPerformance(statuses: $statuses) {
          campaign {
            id
            name
            targetDate
            href
            owner {
              name
              href
            }
          }
          serviceStats {
            total
            totalSuccessful
          }
        }
      }
    }
  }
`;

export const UPDATE_TEAM_MUTATION = gql`
  mutation teamUpdate(
    $id: ID!
    $name: String
    $members: [TeamMembershipUserInput!]
    $parentTeam: IdentifierInput
    $responsibilities: String
    $group: IdentifierInput
  ) {
    teamUpdate(
      input: {
        id: $id
        name: $name
        members: $members
        parentTeam: $parentTeam
        responsibilities: $responsibilities
        group: $group
      }
    ) {
      team {
        ...BasicTeamFragment
      }
      errors {
        message
        path
        title
      }
    }
  }
  ${BASIC_TEAM_FRAGMENT}
`;

export const DELETE_TEAM_MUTATION = gql`
  mutation teamDelete($id: ID!) {
    teamDelete(input: { id: $id }) {
      deletedTeamId
      errors {
        message
        path
        title
      }
    }
  }
`;

export const CREATE_TEAM_MUTATION = gql`
  mutation teamCreate(
    $name: String!
    $members: [TeamMembershipUserInput!]
    $responsibilities: String
    $group: IdentifierInput
    $parentTeam: IdentifierInput
  ) {
    teamCreate(
      input: {
        name: $name
        members: $members
        responsibilities: $responsibilities
        group: $group
        parentTeam: $parentTeam
      }
    ) {
      team {
        ...BasicTeamFragment
      }
      errors {
        message
        path
        title
      }
    }
  }
  ${BASIC_TEAM_FRAGMENT}
`;

export const ADD_TEAM_CONTACT_MUTATION = gql`
  mutation addTeamContact(
    $ownerId: ID!
    $address: String!
    $displayName: String!
    $type: ContactType!
  ) {
    contactCreate(
      input: {
        ownerId: $ownerId
        address: $address
        type: $type
        displayName: $displayName
        displayType: "Secondary"
      }
    ) {
      contact {
        ...ContactFragment
      }
      errors {
        message
        path
        title
      }
    }
  }
  ${CONTACT_FRAGMENT}
`;

export const UPDATE_TEAM_CONTACT_MUTATION = gql`
  mutation updateTeamContact(
    $id: ID!
    $address: String!
    $type: ContactType!
    $displayName: String!
    $makeDefault: Boolean!
  ) {
    contactUpdate(
      input: {
        id: $id
        address: $address
        type: $type
        displayName: $displayName
        displayType: "Secondary"
        makeDefault: $makeDefault
      }
    ) {
      contact {
        ...ContactFragment
      }
      errors {
        message
        path
        title
      }
    }
  }
  ${CONTACT_FRAGMENT}
`;

export const SET_DEFAULT_TEAM_CONTACT_MUTATION = gql`
  mutation setDefaultTeamContact($newTeamId: ID!) {
    contactUpdate(input: { id: $newTeamId, makeDefault: true }) {
      contact {
        ...ContactFragment
      }
      errors {
        message
        path
        title
      }
    }
  }
  ${CONTACT_FRAGMENT}
`;

export const DELETE_TEAM_CONTACT_MUTATION = gql`
  mutation deleteTeamContact($teamId: ID!) {
    contactDelete(input: { id: $teamId }) {
      deletedContactId
      errors {
        message
        path
        title
      }
    }
  }
`;

export const TREE_TEAMS_WITH_PARENT = gql`
  query teamsTree($parentTeam: IdentifierInput, $cursor: String) {
    account {
      teams(
        parentTeam: $parentTeam
        sortBy: name_ASC
        after: $cursor
        first: 50
      ) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          ...BasicTeamFragment
          memberships {
            totalCount
            nodes {
              user {
                id
                name
                avatar
                email
                deactivatedAt
              }
              role
            }
          }
          children: childTeams {
            totalCount
          }
          parentTeam {
            ...BasicTeamFragment
          }
        }
      }
    }
  }
  ${BASIC_TEAM_FRAGMENT}
`;

export const TEAMS_FOR_CUSTOM_ACTIONS = gql`
  query teamsForCustomActions($typeAhead: String) {
    account {
      teams(searchTerm: $typeAhead) {
        nodes {
          display: name
          id
          name
          alias
          aliases
          contacts {
            type
            address
          }
          hasChecks
        }
      }
    }
  }
`;
