export default () => ({
  isCreatingDeploy: false,
  deploy: null,
  errorsCreatingDeploy: [],
  isCreatingCheck: false,
  check: null,
  errorsCreatingCheck: [],
  integration: {},
  isUpdatingIntegration: false,
  errorsUpdatingIntegration: [],
  isSyncReposIntegration: false,
  recentlySyncedRepos: false,
  errorsSyncReposIntegration: [],
  isDeauthIntegration: false,
  successDeauthIntegration: false,
  errorsDeauthIntegration: [],

  isSyncingAlertSources: false,
  recentlySyncedAlertSources: false,
  errorsSyncingAlertSources: [],

  genericIntegrations: [],
  isRequestingGenericIntegrations: false,
  errorsRequestingGenericIntegrations: [],

  integrationGroups: [],
  errorsSettingGroup: [],

  integrations: [],
  isQueryingIntegrations: false,

  installUrl: null,
  isCreatingIntegration: false,
  errorsCreatingIntegration: [],

  isUpdatingIntegrationCredentials: false,
  updatingIntegrationCredentialsSuccess: false,
  errorsUpdatingIntegrationCredentials: [],

  errorsUpdatingDatadogNotificationChannel: [],

  errorsUpdatingSetWebhooksOnMonitors: [],

  errorsUpdatingAllowedRepoVisibility: [],

  updateIntegrationBaseUrlSuccess: false,
  errorsUpdatingIntegrationBaseUrl: [],

  isFetchingGitIntegration: false,
  errorsFetchingGitIntegration: [],
  gitIntegrations: [],

  availableIntegrations: [],
  isFetchingAvailableIntegrations: false,
  errorsFetchingAvailableIntegrations: [],

  integrationCategories: [],
  isFetchingIntegrationCategories: false,
  errorsFetchingIntegrationCategories: [],

  isDeletingIntegration: false,
  successDeletingIntegration: false,
  errorsDeletingIntegration: [],

  isValidatingIntegrationCredentials: false,
  organizationsFromValidatedCredentials: [],
  userFromValidatedCredentials: undefined,
  integrationValidationCredentialsErrors: [],
  tokenOwnerChange: null,

  isUpdatingIntegrationServiceDiscoveryEnabled: false,
  isUpdatingIntegrationServiceDiscoveryIgnoreRules: false,

  isUpdatingIntegrationSBOMGenerationEnabled: false,

  isFetchingIntegration: false,
  errorsFetchingIntegration: [],

  isFetchingNewRelicAccounts: false,
  errorsFetchingNewRelicAccounts: [],
  newRelicAccounts: [],

  isCreatingSampleGitIntegration: false,
  errorsCreatingSampleGitIntegration: [],
  sampleGitIntegration: null,

  isSyncingIntegration: false,
  recentlySyncedIntegration: false,
  errorsSyncingIntegration: [],

  errorsUpdatingTeamSync: [],
  errorsUpdatingWarning: [],

  isSyncTeamsIntegration: false,
  recentlySyncedTeams: true,
  errorsSyncTeamsIntegration: [],

  isUpdatingCodeIssuesOnIntegration: false,

  updateIntegrationConfigMappingSuccess: false,
  errorsUpdatingIntegrationConfigMapping: [],

  isUpdatingFireHydrantIntegration: false,
  errorsUpdatingFireHydrantIntegration: [],
  fireHydrantIntegrationUpdateSuccess: false,
});
